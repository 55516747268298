/* Common Imports */

import React from "react";
import loadable from "@loadable/component";

/* Component Imports */

const Layout = loadable(() => import("../components/Layout"));

/* Redux Imports */

import { createStore, applyMiddleware } from "redux";
import GlobalReducer from "../components/redux-components/GlobalReducer";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

/* Data Imports */

import AppConfig from "../AppConfig";

/* Create Redux Store */

const reduxStore = createStore(GlobalReducer, applyMiddleware(thunk));

/* Page Section Imports */

const SPPContainer = loadable(() =>
  import("../components/page-sections/ittina-mahavir/SPPContainer")
);
const ContactUs = loadable(() =>
  import("../components/page-sections/ittina-mahavir/ContactUs")
);
const Gallery = loadable(() =>
  import("../components/page-sections/ittina-mahavir/Gallery")
);


export const getStaticProps = async (props) => {
  return {
    props: {
      ittinaMahavir: await (
        await fetch(encodeURI(AppConfig.apiUrlPrefix + "ittina-mahavir"))
      ).json(),
    },
  };
};

const Index = (props) => {
  return (
    <Provider store={reduxStore}>
      <React.Fragment>
        <Layout>
          <SPPContainer defaultPropertyData={props.ittinaMahavir} />
          <span id="gallery"></span>
          <Gallery />
          <span id="contact-us"></span>
          <ContactUs />
        </Layout>
      </React.Fragment>
    </Provider>
  );
};

export default Index;
